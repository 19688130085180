import { useState } from "react";

const usePostMessage = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const postMessage = async (messageData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      // usePostMessage.js
      const response = await fetch("http://localhost:4000/api/messages", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageData),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const sentMessage = await response.json();
      setSuccess(true);
    } catch (err) {
      setError(err.message);
      setLoading(false)
    } finally {
      setLoading(false);
    }
  };

  return { postMessage, isLoading, error, success };
};

export default usePostMessage;
