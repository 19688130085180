import React, { useState, useEffect } from "react";
import AdminSection from "../components/AdminSection";
import usePostMessage from "../hooks/usePostMessage";
import useFetchMessages from "../hooks/useFetchMessages";

function ContactUs() {
  const { messages, isLoading, Error } = useFetchMessages();
  const { postMessage, loading, error, success } = usePostMessage();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [buttonText, setButtonText] = useState("Send Message");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setButtonText("Send Message");
  };

  useEffect(() => {
    const { name, email, phone, message } = formData;
    if (name && email && phone && message) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending Message...");
    await postMessage(formData);
    if (success) {
      setButtonText("Message Sent");
    } else if (error) {
      setButtonText("Send Message");
    }
  };

  useEffect(() => {
    if (success) {
      setFormData({ name: "", email: "", phone: "", message: "" });
    }
  }, [success]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-700 to-purple-900 flex flex-col">
      <div className="flex-grow flex flex-col md:flex-row mt-40 mb-20 overflow-hidden">
        <div className="md:w-1/2 w-full pl-5 md:pl-20 py-10 flex flex-col justify-center space-y-4">
          <h1 className="text-white text-2xl md:text-4xl font-bold font-playfair italic">
            Let's stay in touch.
          </h1>
          <div className="text-white text-lg space-y-10">
            <div>
              <p className="font-light underline">Email:</p>
              <p className="font-playfair font-light text-lg md:text-xl italic break-words">
                contact@alpha-seti.co.uk
              </p>
            </div>
            <div>
              <p className="font-light underline">In person:</p>
              <p className="font-playfair font-light text-lg md:text-xl italic break-words">
                2 Campion Road, E10 5GN, London
              </p>
            </div>
            <div>
              <p className="font-light underline">Drop us a call:</p>
              <p className="font-playfair font-light text-lg md:text-xl italic break-words">
                +44 777 371 2447
              </p>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 w-full px-5 md:px-10 flex flex-col justify-center items-center overflow-hidden">
          <form onSubmit={handleSubmit} className="w-full max-w-lg space-y-6">
            <div className="w-full">
              <label className="text-white text-sm font-bold uppercase tracking-wide">
                Name
              </label>
              <input
                className="appearance-none block w-full bg-transparent text-white border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:border-purple-500"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                style={{ borderWidth: "1px" }} // Thin border
              />
            </div>
            <div className="w-full">
              <label className="text-white text-sm font-bold uppercase tracking-wide">
                Email
              </label>
              <input
                className="appearance-none block w-full bg-transparent text-white border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:border-purple-500"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                style={{ borderWidth: "1px" }} // Thin border
              />
            </div>
            <div className="w-full">
              <label className="text-white text-sm font-bold uppercase tracking-wide">
                Phone
              </label>
              <input
                className="appearance-none block w-full bg-transparent text-white border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:border-purple-500"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                style={{ borderWidth: "1px" }} // Thin border
              />
            </div>
            <div className="w-full">
              <label className="text-white text-sm font-bold uppercase tracking-wide">
                Message
              </label>
              <textarea
                className="appearance-none block w-full bg-transparent text-white border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:border-purple-500"
                name="message"
                value={formData.message}
                onChange={handleChange}
                style={{ borderWidth: "1px" }} // Thin border
              />
            </div>
            <button
              type="submit"
              className={`py-3 px-6 rounded text-lg transition-colors duration-300 mt-6 w-full ${
                isButtonDisabled || loading
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-purple-500 hover:bg-purple-400"
              }`}
              style={{ fontSize: "1.125rem", marginBottom: "20px" }} // Equivalent to Tailwind's text-xl
              disabled={loading || isButtonDisabled}
            >
              {buttonText}
            </button>
          </form>
        </div>
        {console.log(messages)}
      </div>
      <AdminSection />
    </div>
  );
}

export default ContactUs;
