// App.js

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Projects from "./pages/Projects";
import Privacy from "./pages/Privacy";
import Services from "./pages/Services";
import CookieConsent from "./components/CookieConsent";
import CookieSettings from "./pages/CookieSettings";
import ScrollToTop from "./components/ScrollToTop"; // Import ScrollToTop component
import PageNotFound from "./components/PageNotFound";
import Terms from "./pages/Terms";

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(
    sessionStorage.getItem("cookiesAccepted") === "true"
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setCookiesAccepted(sessionStorage.getItem("cookiesAccepted") === "true");
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleAccept = () => {
    setCookiesAccepted(true);
    // Save cookie preference in session storage
    sessionStorage.setItem("cookiesAccepted", "true");
  };

  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop component here */}
      <div className="App">
        {!cookiesAccepted && <CookieConsent onAccept={handleAccept} />}
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/services" element={<Services />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cookie-settings" element={<CookieSettings />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="/500" element={<PageNotFound />} />
          <Route path="/502" element={<PageNotFound />} />
          <Route path="/503" element={<PageNotFound />} />
          <Route path="/504" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
