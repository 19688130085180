import React from "react";
import AdminSection from "../components/AdminSection";
import cyber from "../images/cyber.jpg"
import project_d from "../images/port12.jpg"
import service_design from "../images/service_design.jpg"
import service_design2 from "../images/process_map2.jpg"

const ServicesSection = () => {
  const services = [
    {
      id: 1,
      title: "Business Analysis",
      description:
        "Our Business Analysis service helps you identify your business needs, streamline processes, and implement effective solutions. We work closely with your team to ensure every project aligns with your strategic goals and drives your business forward.",
      imageUrl: service_design,
    },
    {
      id: 2,
      title: "Project Delivery",
      description:
        "Our Project Delivery service ensures your projects are completed on time, within budget, and to the highest quality standards. We manage every phase from planning and execution to monitoring and closing, aligning with your business goals to deliver successful outcomes.",
      imageUrl: project_d,
    },
    {
      id: 3,
      title: "Service Desing",
      description:
        "Our Service Design service focuses on creating seamless and efficient customer experiences. We work with you to understand your service requirements, identify areas for improvement, and develop innovative solutions that enhance customer satisfaction and drive business success.",
      imageUrl: service_design2,
    },
    ,
    {
      id: 4,
      title: "Cybersecurity Governance",
      description:
        "Our Cybersecurity Governance service helps you establish robust frameworks to protect your organization from cyber threats. We develop and implement policies, procedures, and controls that ensure compliance, mitigate risks, and safeguard your critical assets.",
      imageUrl: cyber,
    }
    // Add more services as needed
  ];

  return (
    <div>
      <div className="bg-black min-h-screen flex flex-col items-center justify-center px-4">
        <h1 className="text-white text-4xl font-playfair mt-20 mb-10">
          Our Services
        </h1>

        <div className="new-grid grid grid-cols-1 gap-4 w-4/5 md:w-3/5 overflow-hidden pt-10 pb-20">
          {services.length === 0 ? (
            <div>No services available</div>
          ) : (
            services.map((service) => (
              <div
                key={service.id}
                className="flex flex-col md:flex-row items-center justify-center overflow-hidden"
              >
                <div className="w-full md:w-1/3 p-2">
                  <div className="aspect-w-1 aspect-h-1 overflow-hidden">
                    <img
                      src={service.imageUrl}
                      alt={service.title}
                      className="object-cover w-full h-full"
                    />
                  </div>
                </div>
                <div className="w-full md:w-2/3 p-4 text-white overflow-hidden">
                  <h2 className="text-2xl font-playfair italic mb-2">
                    {service.title}
                  </h2>
                  <p className="text-lg">{service.description}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <AdminSection />
    </div>
  );
};

export default ServicesSection;
